/* Material design component style fixes */

mat-card {
  padding: 16px;
}

mat-dialog-container .mdc-dialog__surface {
  padding: 24px;
}

.mat-mdc-form-field {
  display: block !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mdc-floating-label,
.mdc-floating-label mat-label {
  pointer-events: none !important;
}

formly-wrapper-mat-form-field {
  display: block;
}

.mat-mdc-form-field-bottom-align::before {
  height: mat.m2-line-height(theme-indigo-blue.$rcg-assist-theme-typography, 'caption') !important;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 100000;
}

.mat-mdc-dialog-content {
  max-height: unset !important;
}

mat-icon {
  min-width: 24px;
}
