/* Formly material design component style fixes */

.mat-mdc-form-field-infix {
  &:has(formly-field-mat-checkbox) {
    padding-top: 10px !important;
    padding-bottom: 0 !important;
  }
}

.mat-mdc-text-field-wrapper {
  &:has(formly-field-mat-checkbox) {
    padding-left: 4px;
  }
}

.rcg-multiline-mat-tooltip {
  white-space: pre-line !important;
}

.rcg-multiline-mat-tooltip-left {
  white-space: pre-line !important;

  .mdc-tooltip__surface {
    text-align: left;
  }
}

.mat-mdc-tooltip-panel:has(.rcg-mat-tooltip-disable-hover) {
  pointer-events: none !important;
}

.mat-mdc-form-field-subscript-wrapper:not(:has(mat-error)) {
  display: none;
}
