@use 'sass:color';
@use 'sass:map';

@mixin app-theme($theme) {
  // Theme collor palletes
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warning: map.get($theme, warning);
  $background: map.get($theme, background);

  // Colors
  $primary-color: map.get($primary, 500);
  $primary-color-50: map.get($primary, $key: 50);
  $primary-color-100: map.get($primary, $key: 100);
  $primary-color-200: map.get(
    $map: $primary,
    $key: 200,
  );
  $primary-contrast: map.get($primary, 500-contrast);

  $background-color: map.get($background, background);

  body {
    --app-primary-color: #{$primary-color};
    --app-primary-color-100: #{$primary-color-100};
    --app-primary-color-200: #{$primary-color-200};

    --app-primary-contrast-color: #{$primary-contrast};

    --app-background-color: #{$background-color};

    --rcg-selected-color: #{$primary-color-50};
    --rcg-unselected-color: inherit;
  }

  .theme-bg-color {
    background-color: $background-color;
  }

  .mat-mdc-tabs-theme-bg-color .mat-mdc-tab-header {
    background-color: $background-color;
  }

  .rcg-active-route {
    background-color: $primary-color-50 !important;

    &:focus {
      background-color: $primary-color-50 !important;
    }

    &:hover {
      background-color: color.adjust($primary-color-50, $lightness: -0.2%) !important;
    }
  }

  .rcg-active-route-card {
    background-color: $primary-color-100 !important;

    &:focus {
      background-color: $primary-color-100 !important;
    }

    &:hover {
      background-color: color.adjust($primary-color-100, $lightness: -0.2%) !important;
    }
  }

  .list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }

  .list-item:hover {
    box-shadow: 1px 1px 8px 2px $primary-color-100;
  }

  .rcg-card-hover-shadow:hover {
    box-shadow: 1px 1px 8px 2px $primary-color-100;
  }

  .round-border-without-shadow {
    border-radius: 50%;
    border: 2px solid $primary-color;
  }

  .rcg-right-border-color {
    border-right: 3px solid $primary-color;
  }
}
