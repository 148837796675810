.rcg-error-snackbar .mdc-snackbar__surface {
  color: #ffffff !important;
  background-color: #500 !important;
}

.rcg-warning-snackbar .mdc-snackbar__surface {
  color: #ffffff !important;
  background-color: #550 !important;
}

.rcg-info-snackbar .mdc-snackbar__surface {
  color: #ffffff !important;
  background-color: #005 !important;
}
