@import '../../../libs/core/src/styles/theme.scss';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  overflow: hidden;

  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.row-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

/*ANIMATIONS*/
.rcg-fade-in {
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
  -o-animation: fadein 1.5s; /* Opera < 12.1 */
  animation: fadein 1.5s;
}

.rcg-quick-fade-in {
  -webkit-animation: fadein 0.75s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.75s; /* Firefox < 16 */
  -o-animation: fadein 0.75s; /* Opera < 12.1 */
  animation: fadein 0.75s;
}

.sentry-error-embed-wrapper {
  z-index: 10000000 !important;
}

body:has(rcg-html-editor.fullscreen) {
  .html-editor-fullscreen-hide {
    display: none;
  }
}

@import '../../../libs/core/src/styles/dialog.scss';
@import '../../../libs/standalone/src/styles/resizable.scss';
@import '../../../libs/standalone/src/styles/snackbar.scss';

@import '../../../libs/core/src/styles/fix/formly.scss';
@import '../../../libs/core/src/styles/fix/chrome-scrollbar.scss';
