@use 'sass:map';

@mixin avatar-module-theme($theme) {
  // Include components specific themes

  // Determine basic palletes for all main areas
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warning: map.get($theme, warn);

  // Set theme specific colors
  $m-primary-color: map.get($primary, 500);
  $m-primary-color-contrast: map.get($primary, default-contrast);
  $m-primary-color-50: map.get($primary, 50);
  $m-primary-color-100: map.get($primary, 100);
  $m-primary-color-400: map.get($primary, 400);
  $m-primary-color-700: map.get($primary, 700);
  $m-primary-color-800: map.get($primary, 800);
  $m-accent-color-800: map.get($accent, 800);
  $m-accent-color: map.get($accent, 500);
  $m-warn-color-100: map.get($warning, 100);
  $m-warn-color: map.get($warning, 100);

  $foreground: map.get($theme, foreground);

  // These colors are same regardles of the theme selected
  $m-qualify-color: #4caf50;
  $m-not-qualify-color: #f44336;
  $m-conditional-qualify-color: #fcd418;

  .avatar-accent {
    background-color: $m-accent-color;
  }

  .avatar-primary {
    background-color: $m-primary-color;
  }

  .avatar-warn {
    background-color: $m-warn-color;
  }

  .avatar-text {
    color: $m-primary-color-contrast;
  }
}
