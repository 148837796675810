/* Chrome scrollbar improvement */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #9b9b9b60;
  border-radius: 20px;
  border: transparent;

  cursor: pointer;

  &:hover {
    background-color: #9b9b9b80;
  }
}
